import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';

import Header from './components/Header';
import Websocket from './components/Websocket';

import Capture from './components/Capture';
import ReceiptList from './components/ReceiptList';
import Receipt from './components/Receipt';
import ReceiptForm from './components/ReceiptForm';

import receipts from './api/receipts';

class App extends React.Component {
  ws = null;
  state = {
    loading: false,
    receipts: [],
  }
  componentDidMount() {
    this.setState({ loading: true }, () => {
      receipts.get()
      .then(res => {
        this.setState({
          loading: false,
          receipts: res.data
        });
      },
      err => {
        console.error(err);
        this.setState({
          loading: false
        });
      });
    });
  }

  renderLoader() {
    if ( this.state.loading ) {
      return (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      );
    }
    return '';
  }
  render() {
    return (
      <div className="App">
        <Router>
          <div className="ui container">
            <Header />
            <Route path="/capture">
              <div className="ui segment">
                <Capture />
              </div>
            </Route>
            <Route path="/new">
              <div className="ui segment">
                <ReceiptForm />
              </div>
            </Route>
            <Route path="/ws" exact>
              <Websocket />
            </Route>
            <Route path="/receipt/:id(\d+)"
              component={ props => (
                <div className="ui segment">
                  <Receipt receipt={ props.match.params.id && this.state.receipts.find(a => a.id === parseInt(props.match.params.id, 10)) } />
                </div>
              )}
            />
            <Route path="/edit/:id(\d+)"
              component={ props => (
                <div className="ui segment">
                  <ReceiptForm receipt={ props.match.params.id && this.state.receipts.find(a => a.id === parseInt(props.match.params.id, 10)) } />
                </div>
              )}
            />
            <Route path="/">
              <div className="ui segment">
                { this.renderLoader() }
                <ReceiptList receipts={ this.state.receipts } />
              </div>
            </Route>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
