import React from 'react';

const FormField = ({ children, errors, label, fieldName, formState, touched }) => {
  return (
    <div className={ `field${ errors[fieldName] && (touched[fieldName] || formState.submitted) ? ' error' : '' }` }>
      <label>{ label }</label>
      { children }
      { errors[fieldName] && (touched[fieldName] || formState.submitted) && <div className="ui pointing red basic label">{ errors[fieldName] }</div> }
    </div>
  );
}

export default FormField;
