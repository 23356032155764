import React from 'react';

import Title from './common/Title';

const Receipt = ({ receipt }) => {
  return (
    <div className="Receipt">
      <Title title='Receipt' />
      <pre>
        { JSON.stringify(receipt, null, 2) }
      </pre>
    </div>
  );
}

export default Receipt;
