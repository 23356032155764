import React from 'react';
import './Title.css';


const Title = ({ children, title, flex = false }) => {
  return (
    <div className="Title">
      <div className={ 'content', flex ? ' flex' : '' }>
        <h1 className="ui header">
          { title }
        </h1>
        { flex ? <span className="spacer"></span> : '' }
        { children }
      </div>
      <div className="ui divider"></div>
    </div>
  );
}

export default Title;
