import React from 'react';
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="Header">
      <div className="ui secondary pointing menu">
        <Link to="/" className="item">Home</Link>
        <Link to="/ws" className="item">WS</Link>
        {/* <Link to="/edit/:id(\\d+)" className="item">Edit</Link> */}
        <Link to="/new" className="item">New</Link>
        <div className="right menu">
          <a className="ui item">
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
