const ReceiptModel = {
  id: null,
  companyName: '',
  companyAddress: '',
  companyEik: '',
  companyId: 0,
  shopName: '',
  shopAddress: '',
  shopZdds: '',
  shopId: 0,
  operatorNumber: '',
  operatorName: '',
  operatorId: '',
  uniqueNumber: '',
  total: 0.00,
  currency: '',
  xr: 1,
  number: '',
  datetime: '',
  articles: 0,
  fiskDevice: '',
  fiskMemory: '',
  checksum: '',
  userId: null,
  rawContent: '',
  dateCreated: '',
  dateUpdated: null
};

export default ReceiptModel;
