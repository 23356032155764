import React from 'react';
import './Capture.css';

// import Camera from './Camera';
import Snapshot from './Snapshot';

class Capture extends React.Component {
  state = {
    camera: false,
    snapshots: [],
  }
//   handleCapture = ({ blob, base64 }) => {
// // console.log('handleCapture', blob, base64);
//     this.setState({ snapshots: [...this.state.snapshots, { id: Math.random(), base64 }] });
//   }
  handleFileChange = e => {
// console.log('handleFileChange', e);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ snapshots: [...this.state.snapshots, { id: Math.random(), base64: reader.result }] });
    }
    reader.readAsDataURL(file);
  }
  handleRemove = id => {
// console.log('handleRemove', id);
    const snapshots = this.state.snapshots.filter(a => a.id !== id)
    this.setState({ snapshots });
  }
  handleSend = () => {
// console.log('handleSend');
    this.ws.sendData({ type: 'file', data: { id: 1, content: this.state.snapshots } });
  }

  renderSendToBackendButton = () => {
    if ( ! this.state.snapshots.length ) {
      return '';
    }
    return (
      <div>
        <div className="ui divider"></div>
        <button
          className="ui primary button"
          onClick={ this.handleSend }
        >Send to backend</button>
      </div>
    );
  }
  // renderCamera() {
  //   if ( ! this.state.camera ) {
  //     return '';
  //   }

  //   return (
  //     <Camera
  //       onCapture={ this.handleCapture }
  //     />
  //   );
  // }
  renderSnapshots() {
    if ( ! this.state.snapshots.length ) {
      return '';
    }
    return (
      <div className="ui grid">
        { this.state.snapshots.map(a => (
        <Snapshot
          key={ a.id }
          base64={ a.base64 }
          onRemove={ () => this.handleRemove(a.id) }
        />
        )) }
      </div>
    );
  }

  render() {
    return (
      <div className="Capture">
        {/* <div className="ui toggle checkbox">
          <input
            type="checkbox"
            checked={ this.state.camera }
            onChange={ e => this.setState({ camera: e.target.checked }) }
          />
          <label>Use Camera</label>
        </div> */}
        <label
          className="ui icon button"
        >
          <input type="file" onChange={ this.handleFileChange }/>
          <i className="ui camera icon"></i>
          {/* Upload Image */}
        </label>
        {/* { this.renderCamera() } */}
        {/* <div className="ui divider"></div> */}
        { this.renderSnapshots() }
        { this.renderSendToBackendButton() }
      </div>
    );
  }
}

export default Capture;
