import React from 'react';

const FormError = ({ _ }) => {
  return (
    <div className="ui error message">
      <div className="header">Error</div>
      <p>There are some errors on the form.</p>
    </div>
  );
}

export default FormError;
