import React from 'react';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

import Title from './common/Title';
import Capture from './Capture';

const ReceiptList = ({ receipts }) => {
  const currencyTotal = receipt => {
    if ( receipt.currency !== 'BGN' ) {
      return (
        <td className="collapsing right aligned">
          <CurrencyFormat
            value={ receipt.total }
            displayType='text'
            decimalScale={2}
            fixedDecimalScale
            suffix={ ` ${receipt.currency}` }
          />
        </td>
      );
    } else {
      return (
        <td className="collapsing">
        </td>
      );
    }
  }
  const totalValue = receipt => {
    const xr = receipt.currency !== 'BGN' ? receipt.xr : 1;
    return Math.round((receipt.total * xr + Number.EPSILON) * 100) / 100
  }
  return (
    <div className="ReceiptList">
      <Title title='Receipt List' flex>
        {/* <Link to={ `/capture` }>
          {/-* <button className="ui mini button">Add</button> *-/}
          <button className="ui mini icon button"><i class="camera icon"></i></button>
        </Link> */}
        <Capture />
      </Title>
      <table className="ui compact single line striped unstackable table">
        <tbody>
          { receipts.map(a => (
            <tr key={ a.id } className={ `receipt-${a.id}` }>
              <td className="collapsing"><Moment format="DD.MM.YYYY HH:mm">{ a.datetime }</Moment></td>
              <td><Link to={ `/receipt/${a.id}` }>{ a.shopName }</Link></td>
              { currencyTotal(a) }
              <td className="collapsing right aligned">
                <CurrencyFormat
                  value={ totalValue(a) }
                  displayType='text'
                  decimalScale={2}
                  fixedDecimalScale
                />
              </td>
              <td className="collapsing">
                <Link to={ `/edit/${a.id}` }>
                  <button className="ui mini button">
                    Edit
                  </button>
                </Link>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  );
}

export default ReceiptList;
