let serviceInstance = null;

class WebSocketService {
  constructor(url, token, hardReset) {
    if ( ! serviceInstance || hardReset ) {
      serviceInstance = this;
      if ( ! url ) {
        throw new Error('No url provided for new WebSocket connection!');
      }
      try {
        this.ws = new WebSocket(url);
        this.url = url;
        this.token = token;
      } catch(exception) {
        console.log(exception);
      }
    }
    return serviceInstance;
  }

  sendData = data => {
// console.log('sendData', data);
    if ( this.ws.readyState === 1 ) {
      this.ws.send(JSON.stringify(data));
    }
  }
}

export default WebSocketService;
