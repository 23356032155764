/**
 * Code adapted from https://codesandbox.io/s/74pzm9lkq6
 */

import React from 'react';
import './Snapshot.css';

class Snapshot extends React.Component {
  state = {
  }
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }
  componentDidMount() {
    const img = new Image();
    img.onload = () => {
      this.canvasRef.current.width = img.width;
      this.canvasRef.current.height = img.height;
      this.canvasRef.current.getContext('2d').drawImage(img, 0, 0);
    }
    img.src = this.props.base64;
  }

  render() {
    return (
      <div className="Snapshot four wide column">
        <canvas ref={ this.canvasRef } />
        <button
          className="ui negative button snapshot-remove"
          onClick={ this.props.onRemove }
        >Remove</button>
      </div>
    );
  }
}

export default Snapshot;
