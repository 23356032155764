import React from 'react';

import WebSocketService from '../services/websocket.service';

class Websocket extends React.Component {
  ws = null;
  state = {
    connected: false,
    messages: [],
  }
  componentDidMount() {
    this.ws = this.ws || new WebSocketService(`${process.env.REACT_APP_WS_URL}`).ws;
    this.ws.onmessage = event => {
// console.log('onmessage')
      const msg = JSON.parse(event.data)
      if (msg.type)
      switch (msg.type) {
        case 'message':
          this.setState({ messages: [...this.state.messages, msg.data] })
          break;
        case 'clear':
          this.setState({ messages: [] })
          break;
        default:
          console.log(msg);
      }
    }
    this.ws.onopen = event => {
console.log('ws open');
      this.setState({ connected: true });
    };
    this.ws.onclose = event => {
console.log('ws close');
      this.setState({ connected: false });
    };
    this.ws.onerror = error => {
console.log('ws error');
    };
  }

  hancleSendHello = () => {
// console.log('hancleSendHello');
    this.sendData({
      type: 'message',
      data: 'Hello Server!'
    });
  }
  handleSendClear = () => {
// console.log('handleSendClear');
    this.sendData({
      type: 'clear'
    });
  }
  sendData = data => {
// console.log('sendData', data);
    if ( this.ws.readyState === 1 ) {
      this.ws.send(JSON.stringify(data));
    }
  }

  render() {
    return (
      <div className="Websocket">
        <button
          className="ui button"
          onClick={ this.hancleSendHello }
          disabled={ ! this.state.connected }
        >Say "Hi!"</button>
        <button
          className="ui button"
          onClick={ this.handleSendClear }
          disabled={ ! this.state.connected }
        >Clear</button>
        {/* <ul>
          {this.state.messages.map((a, i) => (
            <li key={ i }>{ a }</li>
          ))}
        </ul> */}
      </div>
    );
  }
}

export default Websocket;
