import React from 'react';
import './ReceiptList.css';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import ReceiptModel from '../models/receipt.model';
import Title from './common/Title';
import FormError from './common/FormError';
import FormField from './common/FormField';

const ReceiptSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  companyAddress: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  companyEik: Yup.string()
    .length(9, 'Must have exactly 9 chars')
    .required('This field is required'),
  shopName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  // shopAddress: Yup.string()
  //   .min(2, 'Too Short!')
  //   .max(50, 'Too Long!')
  //   .required('This field is required'),
  // shopZdds: Yup.string()
  //   .length(11, 'Must have exactly 11 chars')
  //   .matches(/BG\d+/, 'Incorrect format')
  //   .required('This field is required'),
  // operatorNumber: Yup.string()
  //   .required('This field is required'),
  // operatorName: Yup.string()
  //   .required('This field is required'),
  // operatorId: Yup.string()
  //   .required('This field is required'),
  // uniqueNumber: Yup.string()
  //   .matches(/.{8}-\d{4}-\d{7}/, 'Incorrect format'),
  // currency: Yup.number()
  //   .default('BGN')
  //   .required('This field is required'),
  // xr: Yup.number()
  //   .default(1),
  // number: Yup.number()
  //   .required('This field is required'),
  // date: Yup.number()
  //   .required('This field is required'),
  // time: Yup.number()
  //   .required('This field is required'),
  // fiskDevice: Yup.number()
  //   .required('This field is required'),
  // fiskMemory: Yup.number()
  //   .required('This field is required'),
  // checksum: Yup.number()
  //   .required('This field is required'),
  // // email: Yup.string()
  // //   .email('Invalid email')
  // //   .required('Required'),
});

class ReceiptForm extends React.Component {
  state = {
    submitted: false
  }
//   validateRequired = value => {
// // console.log('validateRequired', value);
//     let errorMessage;
//     if ( ! value ) {
//       errorMessage = 'This field is required';
//     }
//     return errorMessage;
//   };
  render() {
    const receipt = this.props.receipt || ReceiptModel;
    return (
      <div className="ReceiptForm">
        <Title title={ receipt && receipt.id ? `Edit Receipt #${ receipt.id }` : 'Add New Receipt' }>
          { ! receipt || ! receipt.id ? <button className="ui button">Capture</button> : '' }
        </Title>
        <Formik
          initialValues={{ ...receipt }}
          validationSchema={ ReceiptSchema }
          validateOnBlur={ false }
          validateOnChange={ true }
          onSubmit={ (values, { setSubmitting }) => {
// console.log('onSubmit', values, setSubmitting);
            this.setState({ submitted: true });
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          { ({ values, errors, touched, isSubmitting, handleSubmit }) => (
            <Form className={ `ui form${ Object.keys(errors).length ? ' error': '' }` }
              onSubmit={ e => { e.preventDefault(); this.setState({ submitted: true }); handleSubmit() } }>
              { Object.keys(errors).length && this.state.submitted ? (
                <FormError />
                ) : '' }
              <div className={ `field${ errors.companyName && (touched.companyName || this.state.submitted) ? ' error' : '' }` }>
                <label>Company Name</label>
                <Field type="text" name="companyName" value={ values.companyName } />
                { errors.companyName && (touched.companyName || this.state.submitted) && <div className="ui pointing red basic label">{ errors.companyName }</div> }
              </div>
              <div className={ `field${ errors.companyAddress && (touched.companyAddress || this.state.submitted) ? ' error' : '' }` }>
                <label>Company Address</label>
                <Field type="text" name="companyAddress" value={ values.companyAddress } />
                { errors.companyAddress && (touched.companyAddress || this.state.submitted) && <div className="ui pointing red basic label">{ errors.companyAddress }</div> }
              </div>
              <div className={ `field${ errors.companyEik && (touched.companyEik || this.state.submitted) ? ' error' : '' }` }>
                <label>Company EIK</label>
                <Field type="number" name="companyEik" value={ values.companyEik } />
                { errors.companyEik && (touched.companyEik || this.state.submitted) && <div className="ui pointing red basic label">{ errors.companyEik }</div> }
              </div>
              <FormField errors={ errors } label="Shop Name" fieldName="shopName" formState={ this.state } touched={ touched }>
                <Field type="text" name="shopName" value={ values.shopName } />
              </FormField>
              { Object.keys(errors).length && this.state.submitted ? (
                <FormError />
                ) : '' }
              <button className="ui button" type="submit" disabled={ isSubmitting }>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ReceiptForm;
